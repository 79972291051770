import React, { Component } from "react";
import { translate } from "react-i18next";
import OwlCarousel from "react-owl-carousel2";

class TourismCarousel extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { t } = this.props,
      { items } = this.props;

    const options = {
      items: 1,
      nav: false,
      dots: true,
      rewind: true,
      autoplay: false,
      animateOut: 'fadeOut',
    };

    return (
      <section className="carousel">
        <div className="carousel__arrows">
          <div className="carousel__arrow carousel__arrow--prev" onClick={() => this.refs.homecarousel.prev()}></div>
          <div className="carousel__arrow carousel__arrow--next" onClick={() => this.refs.homecarousel.next()}></div>
        </div>
        <OwlCarousel
          className="carousel__slides owl-carousel"
          ref="homecarousel"
          options={options}
        >
          {items.map(item => {
            return (
              <div
                className="carousel__slide"
                data-slide={item.id}
                key={item.id}
              >
                <div className="carousel__images">
                  <img className="carousel__img carousel__img--top" src={item.image} alt={item.title} />
                  <img className="carousel__img carousel__img--bottom" src={item.image2} alt={item.title} />
                </div>
                <div className="carousel__container wrapper">
                  <h4 className="carousel__title" dangerouslySetInnerHTML={{ __html: item.title }} />
                  <p className="carousel__description" dangerouslySetInnerHTML={{ __html: item.content }} />
                  <div className="carousel__footer">
                    <p className="carousel__info" dangerouslySetInnerHTML={{ __html: t('tourism.info' )}} />
                    <a className="carousel__button button button--larger" onClick={this.onRegisterClick}>
                      {t('common.tryItForFree')}
                    </a>
                  </div>
                </div>
              </div>
            );
          })}
        </OwlCarousel>

      </section>
    );
  }
}

export default translate("translations")(TourismCarousel);
