import React, { Fragment, Component } from 'react';
import { translate } from "react-i18next";
import CtaBlock from "../../components/cta-block";
import { selectors as cmsSelectors } from 'shared/redux/cms';
import { connect } from 'react-redux';
import Loader from '../../components/loader';
import TitleSection from "../../components/title-section";
import './index.scss';
import { NavBarStyles } from 'components/navBar';
class Terms extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    this.props.navBar.setStyle(NavBarStyles.dark);
  }
  componentWillUnmount() {
    this.props.navBar.setStyle();
  }
  renderItem(item) {
    return (
      <li key={item.text} className={!item.level && 'numbered-list-title'}>
        { item.text }
        { item.items && !!item.items.length &&
          <ol className={item.level === 3 ? 'lettered-list' : 'numbered-list'}>
            { item.items.map(item => this.renderItem(item)) }
          </ol>
        }
      </li>
    );
  }
  render() {
    const { t, content } = this.props;

    return (
      <main>
        {content ?
          <div>
            <section id="terms">
              <TitleSection title={content.title} />
              {content.description &&
                <div className="description">
                  {content.description}
                </div>
              }
              <div className="row typography collapse">
                <div className="large-11 small-centered columns">
                  <div className="anim" data-anim="fadeIn">
                    { this.renderItem({ items: content.content }) }
                  </div>
                </div>
              </div>
            </section>
            <CtaBlock />
          </div> :
          <div className="terms-loader">
            <Loader />
          </div>
        }
      </main>
    );
  }
}
const {
  getTerms
} = cmsSelectors;

const mapStateToProps = state => ({
  content: getTerms(state)
});

export default translate('translations')(connect(
  mapStateToProps
)(Terms));
