import React, { Component } from 'react';
import { translate } from "react-i18next";
import "./index.scss";
import MetaTags from 'react-meta-tags';
import LogotypeStrip from 'components/logotypesStrip';
import Carousel from 'components/carousel';
import SlideShow from './components/realEstateSlideShow';
import { actions as uiActions, ModalTypes } from 'shared/redux/ui';
import { selectors as authSelectors } from 'shared/redux/auth';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import IconArrowTryIt from 'static/home/icons/arrow-try-it.svg';
import AssetHelper from 'AssetHelper';
import i18next from 'i18next';
import TabsComponent from './components/tabsComponent';
import Tabs from '../../components/tabs/tabs';

import 'rc-slider/assets/index.css';
import Slider from 'rc-slider';

const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);
const Handle = Slider.Handle;

const wrapperStyle = { width: 400, margin: 50 };

class RealEstate extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { t, user } = this.props,

      isLoggedIn = user.state === 'loggedIn',
      carouselItems = [
        {
          title: t("realEstate.useCaseHeader1"),
          content: t("realEstate.useCaseDescription1"),
          image: AssetHelper.staticPath('/static/home/real-estate/use-case/img-usecase-1.jpg'),
          logo: AssetHelper.staticPath('static/home/home/logotype-set/spot.svg'),
          id: 1
        },
        {
          title: t("realEstate.useCaseHeader2"),
          content: t("realEstate.useCaseDescription2"),
          image: AssetHelper.staticPath('/static/home/real-estate/use-case/img-usecase-2.jpg'),
          logo: AssetHelper.staticPath('static/home/home/logotype-set/spot.svg'),
          id: 2
        },
        {
          title: t("realEstate.useCaseHeader3"),
          content: t("realEstate.useCaseDescription3"),
          image: AssetHelper.staticPath('/static/home/real-estate/use-case/img-usecase-3.jpg'),
          logo: AssetHelper.staticPath('static/home/home/logotype-set/spot.svg'),
          id: 3
        }
      ],
      carousel2Items = [
        {
          title: t("realEstate.investmentHeading1"),
          content: t("realEstate.investmentDesc1"),
          image: AssetHelper.staticPath('/static/home/real-estate/tabs/tab1.jpg'),
          id: 1
        },
        {
          title: t("realEstate.investmentHeading2"),
          content: t("realEstate.investmentDesc2"),
          image: AssetHelper.staticPath('/static/home/real-estate/tabs/tab2.jpg'),
          id: 2
        },
        {
          title: t("realEstate.investmentHeading3"),
          content: t("realEstate.investmentDesc3"),
          image: AssetHelper.staticPath('/static/home/real-estate/tabs/tab3.jpg'),
          id: 3
        },
        {
          title: t("realEstate.investmentHeading4"),
          content: t("realEstate.investmentDesc4"),
          image: AssetHelper.staticPath('/static/home/real-estate/tabs/tab4.jpg'),
          id: 4
        },
        {
          title: t("realEstate.investmentHeading5"),
          content: t("realEstate.investmentDesc5"),
          image: AssetHelper.staticPath('/static/home/real-estate/tabs/tab5.jpg'),
          id: 5
        }
      ],
      residentialImages = [
        AssetHelper.staticPath('/static/home/real-estate/residential/residential_1.jpg'),
        AssetHelper.staticPath('/static/home/real-estate/residential/residential_2.jpg'),
        AssetHelper.staticPath('/static/home/real-estate/residential/residential_3.jpg'),
      ],
      commerImages1 = [
        AssetHelper.staticPath('/static/home/real-estate/commer/commer_4.jpg'),
      ],
      commerImages2 = [
        AssetHelper.staticPath('/static/home/real-estate/commer/commer_1.jpg'),
        AssetHelper.staticPath('/static/home/real-estate/commer/commer_2.jpg'),
        AssetHelper.staticPath('/static/home/real-estate/commer/commer_3.jpg'),
      ],
      tabs = [
        {
          label: t('realEstate.investmentHeading1'),
          description: t('realEstate.investmentDesc1'),
          img: AssetHelper.staticPath('/static/home/real-estate/tabs/tab1.jpg'),
        },
        {
          label: t('realEstate.investmentHeading2'),
          description: t('realEstate.investmentDesc2'),
          img: AssetHelper.staticPath('/static/home/real-estate/tabs/tab2.jpg'),
        },
        {
          label: t('realEstate.investmentHeading3'),
          description: t('realEstate.investmentDesc3'),
          img: AssetHelper.staticPath('/static/home/real-estate/tabs/tab3.jpg'),
        },
        {
          label: t('realEstate.investmentHeading4'),
          description: t('realEstate.investmentDesc4'),
          img: AssetHelper.staticPath('/static/home/real-estate/tabs/tab4.jpg'),
        },
        {
          label: t('realEstate.investmentHeading5'),
          description: t('realEstate.investmentDesc5'),
          img: AssetHelper.staticPath('/static/home/real-estate/tabs/tab5.jpg'),
        }
      ];

    return (
      <section className="real-estate">
        <MetaTags>
          <title>{t("realEstate.metaTitle")}</title>
          <meta name="description" content={t("realEstate.metaDescription")} />
          <meta name="keywords" content={t("realEstate.metaKeywords")} />
        </MetaTags>
        <section className="static-hero wrapper">
          <div className="static-hero__content">
            <h1 className="static-hero__heading" dangerouslySetInnerHTML={{ __html: t('realEstate.heading') }}></h1>
            <h5 className="static-hero__subheading" dangerouslySetInnerHTML={{ __html: t('realEstate.subheading') }}></h5>
          </div>
        </section>
        <section className="why-use">
          {/*<div style={wrapperStyle}>*/}
            {/*<p>Slider with custom handle</p>*/}
            {/*<Slider min={1} max={8} defaultValue={1} />*/}
          {/*</div>*/}
          <h2 className="why-use__heading">{t('realEstate.whyUse')}</h2>
          <div className="why-use__container">
            <img className="why-use__img" src={AssetHelper.staticPath('/static/home/real-estate/why_EP.jpg')} alt="laptop image" />
            <div className="why-use__content">
              <div className="why-use__content-box">
                <h6 className="why-use__box-heading strip">{t('realEstate.whyUseSubhead1')}</h6>
                <p className="list-item-desc">{t('realEstate.whyUseDesc1')}</p>
              </div>
              <div className="why-use__content-box">
                <h6 className="why-use__box-heading strip">{t('realEstate.whyUseSubhead2')}</h6>
                <p className="list-item-desc">{t('realEstate.whyUseDesc2')}</p>
              </div>
              <div className="why-use__content-box">
                <h6 className="why-use__box-heading strip">{t('realEstate.whyUseSubhead3')}</h6>
                <p className="list-item-desc">{t('realEstate.whyUseDesc3')}</p>
              </div>
            </div>
          </div>
        </section>
        <LogotypeStrip />
        { i18next.language.includes('pl')
          && (
          <section className="asari">
            <h2 className="asari__title">{t('realEstate.asari.title')}</h2>
            <div className="asari__box">
              <div className="asari__box_container">
                <h6 className="asari__box__subtitle">{t('realEstate.asari.subtitle')}</h6>
                <p className="list-item-desc">{t('realEstate.asari.description')}</p>
                <ul className="asari__box-list">
                  <li className="asari__box-item">
                    <p className="list-item-heading asari__item-heading asari__item-heading--1-1">{t('realEstate.asari.listItemHeader1')}</p>
                    <p className="asari__list-item-desc">{t('realEstate.asari.listItemDesc1')}</p>
                  </li>
                  <li className="asari__box-item">
                    <p className="list-item-heading asari__item-heading asari__item-heading--1-2">{t('realEstate.asari.listItemHeader2')}</p>
                    <p className="asari__list-item-desc">{t('realEstate.asari.listItemDesc2')}</p>
                  </li>
                  <li className="asari__box-item">
                    <p className="list-item-heading asari__item-heading asari__item-heading--1-3">{t('realEstate.asari.listItemHeader3')}</p>
                    <p className="asari__list-item-desc">{t('realEstate.asari.listItemDesc3')}</p>
                  </li>
                  <li className="asari__box-item">
                    <p className="list-item-heading asari__item-heading asari__item-heading--1-4">{t('realEstate.asari.listItemHeader4')}</p>
                    <p className="asari__list-item-desc">{t('realEstate.asari.listItemDesc4')}</p>
                  </li>
                </ul>
                <div className="asari__cta">
                  <a className="asari__button button button--larger" href="https://asari.pl/evryplace-edytor-prezentacji-360/">
                    {t('realEstate.asari.linkText')}
                  </a>
                </div>
              </div>
              <div className="image">
                <img src="/static/home/real-estate/laptop.png" />
              </div>
            </div>
          </section>
        )}
        <section className="for-who wrapper">
          <div className="for-who__box">
            <div className="img-responsive for-who__img">
              <SlideShow images={commerImages1} />
            </div>
            <div className="for-who__box-content">
              <h2 className="for-who__box-heading">{t('realEstate.forWhoHeading1')}</h2>
              <ul className="for-who__box-list">
                <li className="for-who__box-item">
                  <p className="list-item-heading for-who__item-heading for-who__item-heading--1-1">{t('realEstate.forWhoBox1heading1')}</p>
                  <p className="list-item-desc">{t('realEstate.forWhoBox1description1')}</p>
                </li>
                <li className="for-who__box-item">
                  <p className="list-item-heading for-who__item-heading for-who__item-heading--1-2">{t('realEstate.forWhoBox1heading2')}</p>
                  <p className="list-item-desc">{t('realEstate.forWhoBox1description2')}</p>
                </li>
                <li className="for-who__box-item">
                  <p className="list-item-heading for-who__item-heading for-who__item-heading--1-3">{t('realEstate.forWhoBox1heading3')}</p>
                  <p className="list-item-desc">{t('realEstate.forWhoBox1description3')}</p>
                </li>
              </ul>
              <div className="for-who__cta">
                {
                  isLoggedIn ?
                    <a className="button button--larger cta__button" dangerouslySetInnerHTML={{ __html: t('common.createPresentation') }} href="/panel/#/projects"></a> :

                    <a className="for-who__button button button--larger" onClick={() => this.props.showModal({ modalType: ModalTypes.Registration })}>
                      {t('realEstate.cta1Button')}
                    </a>
                }
              </div>
            </div>
          </div>
          <div className="for-who__box for-who__box--reversed">
            <div className="img-responsive for-who__img">
              <SlideShow images={residentialImages} />
            </div>
            <div className="for-who__box-content">
              <h2 className="for-who__box-heading">{t('realEstate.forWhoHeading2')}</h2>
              <ul className="for-who__box-list">
                <li className="for-who__box-item">
                  <p className="list-item-heading for-who__item-heading for-who__item-heading--2-1">{t('realEstate.forWhoBox2heading1')}</p>
                  <p className="list-item-desc">{t('realEstate.forWhoBox2description1')}</p>
                </li>
                <li className="for-who__box-item">
                  <p className="list-item-heading for-who__item-heading for-who__item-heading--2-2">{t('realEstate.forWhoBox2heading2')}</p>
                  <p className="list-item-desc">{t('realEstate.forWhoBox2description2')}</p>
                </li>
                <li className="for-who__box-item">
                  <p className="list-item-heading for-who__item-heading for-who__item-heading--2-3">{t('realEstate.forWhoBox2heading3')}</p>
                  <p className="list-item-desc">{t('realEstate.forWhoBox2description3')}</p>
                </li>
              </ul>
              <div className="for-who__cta">
                {
                  isLoggedIn ?
                    <a className="button button--larger cta__button" dangerouslySetInnerHTML={{ __html: t('common.createPresentation') }} href="/panel/#/projects"></a> :

                    <a className="for-who__button button button--larger" onClick={() => this.props.showModal({ modalType: ModalTypes.Registration })}>
                      {t('realEstate.cta2Button')}
                    </a>
                }
              </div>
            </div>
          </div>
          <div className="for-who__box">
            <div className="img-responsive for-who__img">
              <SlideShow images={commerImages2} />
            </div>
            <div className="for-who__box-content">
              <h2 className="for-who__box-heading">{t('realEstate.forWhoHeading3')}</h2>
              <ul className="for-who__box-list">
                <li className="for-who__box-item">
                  <p className="list-item-heading for-who__item-heading for-who__item-heading--3-1">{t('realEstate.forWhoBox3heading1')}</p>
                  <p className="list-item-desc">{t('realEstate.forWhoBox3description1')}</p>
                </li>
                <li className="for-who__box-item">
                  <p className="list-item-heading for-who__item-heading for-who__item-heading--3-2">{t('realEstate.forWhoBox3heading2')}</p>
                  <p className="list-item-desc">{t('realEstate.forWhoBox3description2')}</p>
                </li>
                <li className="for-who__box-item">
                  <p className="list-item-heading for-who__item-heading for-who__item-heading--3-3">{t('realEstate.forWhoBox3heading3')}</p>
                  <p className="list-item-desc">{t('realEstate.forWhoBox3description3')}</p>
                </li>
              </ul>
              <div className="for-who__cta">
                {
                  isLoggedIn ?
                    <a className="button button--larger cta__button" dangerouslySetInnerHTML={{ __html: t('common.createPresentation') }} href="/panel/#/projects"></a> :

                    <a className="for-who__button button button--larger" onClick={() => this.props.showModal({ modalType: ModalTypes.Registration })}>
                      {t('realEstate.cta1Button')}
                    </a>
                }
              </div>
            </div>
          </div>
        </section>
        {/*<section className="use-case wrapper">*/}
        {/*<h3 className="use-case__heading">{t('realEstate.useCase')}</h3>*/}
        {/*<RealEstateCarousel>*/}
        {/*{carouselItems.map(item =>*/}
        {/*<div*/}
        {/*className="carousel__slide"*/}
        {/*id="usecase"*/}
        {/*data-slide={item.id}*/}
        {/*key={item.id}*/}
        {/*>*/}
        {/*<img className="carousel__img" src={item.image} alt={item.title} />*/}
        {/*<div className="carousel__container">*/}
        {/*<img className="carousel__logo" src={item.logo} alt="logo" />*/}
        {/*<h4 className="carousel__title" dangerouslySetInnerHTML={{ __html: item.title }} />*/}
        {/*<p className="carousel__description" dangerouslySetInnerHTML={{ __html: item.content }} />*/}
        {/*<a className="carousel__button button button--larger" onClick={this.onRegisterClick}>*/}
        {/*{t('common.showMore')}*/}
        {/*</a>*/}
        {/*</div>*/}
        {/*</div>*/}
        {/*)}*/}
        {/*</RealEstateCarousel>*/}
        {/*</section>*/}
        <section className="slogan">
          <IconArrowTryIt />
          <h2 className="slogan__head">
            {t('realEstate.tryItHeading')}
          </h2>
          {
            isLoggedIn ?
              <a className="button button--larger button--cta slogan__button" dangerouslySetInnerHTML={{ __html: t('common.createPresentation') }} href="/panel/#/projects"></a> :
              <div style={{ display: 'flex', flexDirection: 'column'}}>
                <a className="button button--larger button--cta slogan__button" onClick={() => this.props.showModal({ modalType: ModalTypes.Registration })}>
                  {t('common.tryItNow')}
                </a>
                <p className="slogan__tagline" style={{ color: 'white', marginTop: 10 }}>{t("home.tagline")}</p>
              </div>
          }
        </section>
        <section className="investment wrapper">
          <div className="investment__mobile">
            <Carousel items={carousel2Items}></Carousel>
          </div>
          <div className="investment__desktop tabs-container">
            <h2 className="investment__desktop-heading">{t('realEstate.investmentHeading')}</h2>
            <Tabs tabs={tabs}>
               <TabsComponent />
              </Tabs>
          </div>
        </section>
      </section>
    );
  }
}

const {
  showModal,
} = uiActions;

const {
  getCurrentUser
} = authSelectors;

const mapStateToProps = state => ({
  user: getCurrentUser(state)
});

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators({
    showModal,
  }, dispatch),
});

export default translate('translations')(connect(
  mapStateToProps,
  mapDispatchToProps,
)(RealEstate));
